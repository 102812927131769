// 账本基本字段
let tableData = [
  {
    label: '序号',
    type: 'index',
    align: 'center',
    show: true,
    width: 80
  },
  {
    label: '链上账户',
    prop: 'publicKey',
    show: true,
    enableSlot: true,
    minWidth: '100%'
  },
  {
    label: '记账哈希',
    prop: 'token',
    show: true,
    enableSlot: true,
    minWidth: 120
  },
  {
    label: '记账类型',
    prop: 'type',
    show: true,
    enableSlot: true,
    align: 'center',
    width: 140
  },
  {
    label: '记账数量',
    prop: 'value',
    show: true,
    enableSlot: true,
    align: 'center',
    width: 140
  },
  // {
  //   label: '操作',
  //   prop: 'row',
  //   align: 'right',
  //   fixed: 'right',
  //   show: true,
  //   enableSlot: true,
  //   width: 100
  // }
  {
    prop: 'operators',
    label: '操作',
    fixed: 'right',
    align: 'right',
    width: 100,
    children: [
      {
        icon: 'el-icon-view',
        label: '查看',
        clickEvent: (thisVue, row) => {
          thisVue.handleDetail(row.publicKey)
        },
        showFilter: () => {
          return true
        }
      }
    ]
  }
]

let headData = {
  title: '记账列表'
}

let tableObj = {
  'tableData': tableData,
  'headData': headData
}

export default tableObj
