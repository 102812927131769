var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table-list',{attrs:{"columns":_vm.columns,"data":_vm.list,"tableOption":{size: 'mini'},"loading":_vm.loading,"fit":"","stripe":"","pageData":_vm.pageData,"headData":_vm.headData},on:{"handlePageChange":_vm.handlePageChange,"handleSizeChange":_vm.handleSizeChange},scopedSlots:_vm._u([{key:"publicKey",fn:function(ref){
var row = ref.row;
return [(row.publicKey)?_c('div',{staticClass:"div-row"},[_c('popover',{attrs:{"width":500,"shard":_vm.shardName,"value":row.publicKey,"isSubStr":false,"divClass":'shenglue-1'}}),_c('i',{staticClass:"el-icon-document-copy",staticStyle:{"margin-left":"10px"},on:{"click":function($event){return _vm.copy($event, row.publicKey)}}})],1):(!row.publicKey)?_c('span',[_vm._v("无")]):_vm._e()]}},{key:"token",fn:function(ref){
var row = ref.row;
return [(row.token)?_c('div',{staticClass:"div-row"},[_c('popover',{attrs:{"width":500,"shard":_vm.shardName,"value":row.token,"isSubStr":false,"divClass":'shenglue-1'}}),_c('i',{staticClass:"el-icon-document-copy",staticStyle:{"margin-left":"10px"},on:{"click":function($event){return _vm.copy($event, row.token)}}})],1):_c('span',[_vm._v("无")])]}},{key:"type",fn:function(ref){
var row = ref.row;
return [(row.type==='Tokens')?_c('span',[_vm._v("记账单位")]):(row.type==='Deposit')?_c('span',[_vm._v("质押中的记账单位")]):(row.type==='Contract')?_c('span',[_vm._v("智能合约通证")]):_c('span',[_vm._v("无")])]}},{key:"value",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.toThousands(row.value)))])]}}])}),_c('el-dialog',{attrs:{"title":_vm.title,"width":"55%","visible":_vm.dialogVisible},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[(_vm.info)?_c('pre',{domProps:{"innerHTML":_vm._s(_vm.info)}}):_c('div',[_vm._v("暂无数据")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }